<template>
  <b-container>
    <b-row>
      <b-col>
        <h4 class="page-title">Payment List</h4>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'PaymentIndex'
  }
</script>

<style>
</style>
